import { useTranslation } from 'next-i18next'
import Dialog from '~/components/widgets/Dialog'
import { getConfig } from '~/config/config'
import { useDialog } from '~/contexts'
import * as styles from './styles'

const { publicRuntimeConfig: config } = getConfig()

export default function AuthDialog(): JSX.Element {
  const { t } = useTranslation('lobby')
  const { toggleAuthDialog } = useDialog()

  function handleAuthClick(): void {
    window.open(`${config.getsetup_io_url}/auth?returnTo=${encodeURIComponent(window.location.href)}`, '_self')
    toggleAuthDialog()
  }

  return (
    <Dialog customClass={styles.customClass} onClose={toggleAuthDialog}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img src="/icons/v2/chat.png" width="96px" />
        </div>
        <h1 className={styles.heading}>{t('authWelcomeTitle')} </h1>
        <h3 className={styles.subheading}>{t('authWelcomeSubtitle')}</h3>
        <div className={styles.buttonContainer}>
          {/* Disabled on 04th June 2024 due to issues with facebook login
          <FacebookLogin />
          <br /> */}
          <button className={styles.button} onClick={handleAuthClick}>
            {t('signUpSlashLogIn')}
          </button>
        </div>
      </div>
    </Dialog>
  )
}
